import React, { useState, useEffect, useCallback, useMemo } from "react";
import { Box, Container, Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import CategorySlider from "./../CategoryPage/CategorySlider";
import { useCategories } from "../../context/CategoriesContext";
import NewsList from "./NewsList";
import api from "../../api";

const CategoryNewsList = () => {
  const { id } = useParams();
  const { categories, loading: categoriesLoading } = useCategories();
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [stories, setStories] = useState([]);
  const [loading, setLoading] = useState(false);
  const [nextPage, setNextPage] = useState(null);

  // Функция для загрузки историй
  const fetchStories = useCallback(
    async ({ categoryId, cursor } = {}) => {
      setLoading(true);
      try {
        const params = {
          page_size: 12,
          ordering: "-creation_at",
        };

        if (categoryId) {
          params.category = categoryId;
        }

        if (cursor) {
          params.cursor = cursor;
        }

        const response = await api.get("/api/v1/stories/", { params });
        const newStories = response.data.results.map((story) => ({
          id: story.id,
          title: story.title,
          creation_at: story.creation_at,
          category: story.category,
          source: story.news_article?.source || { name: "Источник неизвестен" },
          main_image: story.news_article?.main_image || null,
          publication_at: story.news_article?.publication_at,
          url: story.news_article?.url,
        }));

        setStories((prevStories) => [...prevStories, ...newStories]);
        setNextPage(response.data.next); // Сохраняем ссылку на следующую страницу
      } catch (error) {
        console.error("Ошибка при загрузке историй:", error);
      } finally {
        setLoading(false);
      }
    },
    []
  );

  // Загрузка данных при изменении категории или ID
  useEffect(() => {
    setStories([]);
    setNextPage(null);

    if (categories.length > 0) {
      if (id && categories.some((category) => category.id === parseInt(id, 10))) {
        const foundCategory = categories.find((category) => category.id === parseInt(id, 10));
        setSelectedCategory(foundCategory);
        fetchStories({ categoryId: foundCategory.id });
      } else {
        setSelectedCategory(null);
        fetchStories();
      }
    }
  }, [id, categories, fetchStories]);

  // Обработчик для выбора категории
  const handleSelectCategory = useCallback(
    (category) => {
      setStories([]);
      setNextPage(null);

      if (category) {
        setSelectedCategory(category);
        fetchStories({ categoryId: category.id });
      } else {
        setSelectedCategory(null);
        fetchStories();
      }
    },
    [fetchStories]
  );

  const renderedCategories = useMemo(() => categories, [categories]);
  const renderedStories = useMemo(() => stories, [stories]);

  // Функция для загрузки следующей страницы
  const loadMoreStories = useCallback(() => {
    if (nextPage) {
      const cursor = new URL(nextPage).searchParams.get("cursor");
      fetchStories({ cursor });
    }
  }, [nextPage, fetchStories]);

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 0 }}>
      <Box sx={{ width: "100%", display: "flex", flexDirection: "column", mb: 0 }}>
        {selectedCategory ? (
          <Typography
            variant="h5"
            fontWeight={700}
            gutterBottom
            sx={{
              position: "relative",
              display: "inline-block",
              "&:after": {
                content: '""',
                display: "block",
                width: "100%",
                height: "4px",
                backgroundColor: "#d3d3d3",
                position: "absolute",
                bottom: "-4px",
                left: 0,
              },
              "&:before": {
                content: '""',
                display: "inline-block",
                width: "5px",
                height: "4px",
                backgroundColor: "#d3d3d3",
                position: "absolute",
                bottom: "-4px",
                right: "-12px",
              },
            }}
          >
            {selectedCategory.name || "Категория не выбрана"}
          </Typography>
        ) : (
          <Typography variant="h5" fontWeight={700} gutterBottom>
            Актуальные сюжеты
          </Typography>
        )}

        <CategorySlider
          categories={renderedCategories}
          onSelectCategory={handleSelectCategory}
          selectedCategory={selectedCategory}
          localMode={true}
        />

        <NewsList
          stories={renderedStories}
          showSkeleton={categoriesLoading || loading}
          onLoadMore={nextPage ? loadMoreStories : null} // Передаем функцию загрузки следующей страницы
        />
      </Box>
    </Container>
  );
};

export default CategoryNewsList;
