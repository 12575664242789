import React from "react";
import { Menu, MenuItem } from "@mui/material";
import { Link } from "react-router-dom";
import { useUser } from "../../context/UserContext";

const UserMenu = ({ anchorEl, setAnchorEl, handleLogout }) => {
  const { user } = useUser();

  // Проверка, является ли пользователь администратором
  const isAdmin = user?.is_source_admin || user?.email === "s.chikita@mail.ru";

  return (
    <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)}>
      <MenuItem component={Link} to="/profile" onClick={() => setAnchorEl(null)}>
        Личный кабинет
      </MenuItem>
      {isAdmin && (
        <MenuItem component={Link} to="/adminpage" onClick={() => setAnchorEl(null)}>
          Мой канал
        </MenuItem>
      )}
      <MenuItem onClick={handleLogout}>Выход</MenuItem>
    </Menu>
  );
};

export default UserMenu;
