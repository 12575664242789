import React, { useEffect, useState, useCallback } from 'react';
import { Box, Grid, Typography, useTheme, Skeleton, Container } from '@mui/material';
import { Link } from 'react-router-dom';
import { useMediaQuery } from '@mui/material';
import api from '../api';
import NewsCard from './NewsCard';
import TitleWithLineAndDot from './TitleWithLineAndDot';

const NewsSection = ({ type, selectedCategory }) => {
  const [stories, setStories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const theme = useTheme();

  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const fontSize = isSmallScreen ? "20px" : "25px";

  const getTitle = () => {
    if (selectedCategory?.name) {
      return selectedCategory.name.trim();
    }
    switch (type) {
      case "popular":
        return "Самые популярные новости";
      case "recommendations":
        return "Рекомендации для Вас";
      case "current":
        return "Текущие новости";
      default:
        return "";
    }
  };

  // Сброс историй и страницы при изменении типа или категории
  useEffect(() => {
    setStories([]);
    setPage(1);
  }, [type, selectedCategory]);

  // Функция для загрузки обычных сюжетов
  const fetchStories = useCallback(async () => {
    let url = `/api/v1/stories/?page=${page}&page_size=12`;
  
    if (type === "popular") {
      url += "&type=popular";
    } else if (type === "current") {
      url = `/api/v1/stories/?page_size=12`;
    }
  
    if (selectedCategory?.id) {
      url += `&category=${selectedCategory.id}`;
    }
  
    setLoading(true);
  
    try {
      const response = await api.get(url);
      const formattedStories = response.data.results.map((story) => ({
        id: story.id,
        title: story.title || "Без названия",
        creation_at: story.creation_at,
        category: story.category || {
          id: null,
          name: "Без категории",
          code_name: "Без категории",
        },
        main_image: story.news_article?.main_image || "",
        summary: story.news_article?.summary || "",
        description: story.news_article?.description || "",
        text: story.news_article?.text || "",
        url: story.news_article?.url || "",
        source: story.news_article?.source || null,
      }));
  
      setStories((prevStories) =>
        page === 1 ? formattedStories : [...prevStories, ...formattedStories]
      );
    } catch (error) {
      console.error("Ошибка при загрузке историй:", error);
    }
  
    setLoading(false);
  }, [type, selectedCategory, page]);
  
  const fetchRecommendations = useCallback(async () => {
    const url = `/api/v1/stories/recommendations/user-recommendations/?page=${page}&num_recommendations=12`;
  
    setLoading(true);
  
    try {
      const response = await api.get(url);
      const formattedStories = response.data.map((story) => ({
        id: story.id,
        title: story.title || story.news_article?.title || "Без названия",
        creation_at: story.creation_at,
        category: story.category || story.news_article?.category || {
          id: null,
          name: "Без категории",
          code_name: "Без категории",
        },
        main_image: story.news_article?.main_image || "",
        summary: story.news_article?.summary || "",
        description: story.news_article?.description || "",
        text: story.news_article?.text || "",
        url: story.news_article?.url || "",
        source: story.news_article?.source || null,
      }));
  
      setStories((prevStories) =>
        page === 1 ? formattedStories : [...prevStories, ...formattedStories]
      );
    } catch (error) {
      console.error("Ошибка при загрузке рекомендаций:", error);
    }
  
    setLoading(false);
  }, [page]);
  
  useEffect(() => {
    if (type === "recommendations") {
      fetchRecommendations();
    } else {
      fetchStories();
    }
  }, [type, selectedCategory, page, fetchStories, fetchRecommendations]);
  
  const renderSkeletons = () => (
    <Grid
      container
      spacing={1}
      sx={{
        paddingLeft: 0,
        paddingRight: 0,
        justifyContent: { xs: "center", sm: "flex-start" },
      }}
    >
      {Array.from(new Array(12)).map((_, index) => (
        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          key={index}
          sx={{ paddingLeft: 0, paddingRight: 0 }}
        >
          <Skeleton variant="rectangular" width="100%" height={320} />
          <Box sx={{ mt: 1 }}>
            <Skeleton variant="text" width="60%" />
            <Skeleton variant="text" width="80%" />
            <Skeleton variant="text" width="40%" />
          </Box>
        </Grid>
      ))}
    </Grid>
  );

  return (
    <Box>
      <Container
        sx={{
          mb: 2,
          paddingLeft: { xs: 2, sm: 4 },
          paddingRight: { xs: 2, sm: 4 },
        }}
      >
        <TitleWithLineAndDot
          title={getTitle()}
          fontSize={fontSize}
          lineHeight={6}
          fontWeight={700}
        />
      </Container>
      <Box>
        {loading && page === 1 ? (
          renderSkeletons()
        ) : (
          <Grid
            container
            spacing={1}
            sx={{
              paddingLeft: { xs: 1 },
              paddingRight: { xs: 1 },
              justifyContent: { xs: "center", sm: "flex-start" },
            }}
          >
            {stories.slice(0, 12).map((story) => (
              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                key={story.id}
                sx={{
                  paddingLeft: { xs: 0 },
                  "@media (min-width: 450px) and (max-width: 600px)": {
                    flexBasis: "50%",
                    maxWidth: "50%",
                  },
                }}
              >
                <Link
                  to={`/story/${story.id}`}
                  style={{ textDecoration: "none" }}
                >
                  <NewsCard
                    item={story}
                    time={story.creation_at}
                    theme={theme}
                    isLink
                  />
                </Link>
              </Grid>
            ))}
          </Grid>
        )}
      </Box>

      {!loading && stories.length === 0 && (
        <Typography variant="h6" align="center" sx={{ mt: 2 }}>
          Нет новостей для отображения.
        </Typography>
      )}
    </Box>
  );
};

export default NewsSection;
