import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useUser } from '../context/UserContext';

const PrivateRoute = ({ element }) => {
  const { user, loading } = useUser();
  const location = useLocation();

  if (loading) {
    return <div>Загрузка...</div>; // Иконка или скелетон вместо пустого текста
  }

  return user ? element : <Navigate to="/login" state={{ from: location }} replace />;
};

export default PrivateRoute;
