import React, { useState, useEffect } from "react";
import { Box, IconButton, Typography, Tooltip, Skeleton } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import api from "../../api";
import { useUser } from "../../context/UserContext";

const ReactionButtons = ({ articleId, initialReactions, loading }) => {
  const [reactions, setReactions] = useState(initialReactions || []);
  const [userReactionId, setUserReactionId] = useState(
    initialReactions?.find((reaction) => reaction.user_selected)?.id || null
  );
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const theme = useTheme();
  const { user } = useUser();

  useEffect(() => {
    if (!initialReactions && !loading) {
      const fetchReactions = async () => {
        try {
          const response = await api.get(
            `/api/v1/reactions/list/story/${articleId}/`
          );
          setReactions(response.data);
          const userReaction = response.data.find(
            (reaction) => reaction.user_selected
          );
          setUserReactionId(userReaction ? userReaction.id : null);
        } catch (error) {
          console.error("Error fetching reactions:", error);
        }
      };
      fetchReactions();
    }
  }, [articleId, initialReactions, loading]);

  const handleReactionClick = async (reaction) => {
    if (!user) {
      setTooltipOpen(true);
      setTimeout(() => setTooltipOpen(false), 2000);
      return;
    }

    try {
      if (reaction.id === userReactionId) {
        await api.post("/api/v1/reactions/remove/", {
          story_article: articleId,
          reaction_type: reaction.id,
        });
        setReactions((prevReactions) =>
          prevReactions.map((r) =>
            r.id === reaction.id ? { ...r, count: Math.max(0, r.count - 1) } : r
          )
        );
        setUserReactionId(null);
      } else {
        if (userReactionId) {
          await api.post("/api/v1/reactions/remove/", {
            story_article: articleId,
            reaction_type: userReactionId,
          });
          setReactions((prevReactions) =>
            prevReactions.map((r) =>
              r.id === userReactionId
                ? { ...r, count: Math.max(0, r.count - 1) }
                : r
            )
          );
        }
        await api.post("/api/v1/reactions/add/", {
          story_article: articleId,
          reaction_type: reaction.id,
        });
        setReactions((prevReactions) =>
          prevReactions.map((r) =>
            r.id === reaction.id ? { ...r, count: r.count + 1 } : r
          )
        );
        setUserReactionId(reaction.id);
      }
    } catch (error) {
      console.error("Error handling reaction:", error);
    }
  };

  return (
    <Tooltip
      title={
        !user ? "Реакции доступны только авторизированным пользователям" : ""
      }
      open={!user && tooltipOpen}
      disableHoverListener
      disableFocusListener
      arrow
    >
      <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
        {loading ? (
          Array.from({ length: initialReactions?.length || 4 }).map((_, index) => (
            <Box
              key={index}
              sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
            >
              <Skeleton variant="circular" width={40} height={40} />
              <Skeleton variant="text" width={20} height={20} />
            </Box>
          ))
        ) : (
          reactions.map((reaction) => (
            <Box
              key={reaction.id}
              sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
            >
              <IconButton
                onClick={() => handleReactionClick(reaction)}
                sx={{
                  color: theme.palette.mode === "dark" ? "#ffffff" : "#000000",
                }}
              >
                <img
                  src={reaction.icon}
                  alt={reaction.description}
                  style={{
                    width: 24,
                    height: 24,
                    filter: theme.palette.mode === "dark" ? "invert(1)" : "none",
                  }}
                />
              </IconButton>
              <Typography
                variant="caption"
                sx={{ color: theme.palette.text.secondary }}
              >
                {reaction.count}
              </Typography>
            </Box>
          ))
        )}
      </Box>
    </Tooltip>
  );
};

export default ReactionButtons;
