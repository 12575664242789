import React from 'react';
import { Box } from '@mui/material';
import { Link } from 'react-router-dom';

const Logo = () => {
  return (
    <Link to="/">
      <Box
        component="img"
        alt="Логотип"
        src="../static/logo.png"
        sx={{
          height: { xs: 30, sm: 60 },
          marginRight: 2,
          filter: (theme) => (theme.palette.mode === 'dark' ? 'invert(1)' : 'none'),
        }}
      />
    </Link>
  );
};

export default Logo;
