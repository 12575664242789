import React, { useEffect, useState } from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ThemeProvider } from './context/ThemeContext';
import Header from './components/Header/Header';
import HomePage from './pages/HomePage';
import LoginPage from './pages/LoginPage';
import ProfilePage from './components/Profile/ProfilePage';
import CategoriesPage from './pages/CategoryPage';
import CookiePolicyPage from './pages/CookiePolicyPage';
import PrivacyPolicy from './pages/PrivacyPolicy';
import DataProcessingPolicyPage from './pages/DataProcessingPolicyPage';
import NewsDetailPage from './pages/NewsDetailPage';
import NotFound from './components/NotFound';
import Footer from './components/Footer/Footer';
import ScrollToTop from './components/ScrollToTop';
import PrivateRoute from './components/PrivateRoute';
import { CategoriesProvider } from './context/CategoriesContext';
import { useUser } from './context/UserContext';
import { getUser } from './api';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

function App() {
  const { setUser } = useUser(); // Берем функцию для установки пользователя из контекста
  const [loading, setLoading] = useState(true);

  // Загружаем данные пользователя при первой отрисовке
  useEffect(() => {
    const fetchUser = async () => {
      try {
        const userData = await getUser(); // Запрос данных пользователя
        setUser(userData); // Устанавливаем данные пользователя в контекст
      } catch (error) {
        console.error('Ошибка при загрузке пользователя:', error);
        setUser(null); // Если запрос не удался, сбрасываем пользователя
      } finally {
        setLoading(false); // Устанавливаем, что загрузка завершена
      }
    };

    fetchUser();
  }, [setUser]);

  // Показываем индикатор загрузки, пока данные пользователя загружаются
  if (loading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <ThemeProvider>
      <CategoriesProvider>
        <Router>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              minHeight: '100vh',
              bgcolor: 'background.default',
              color: 'text.primary',
            }}
          >           
            <Header />
            <Box className="App-content" sx={{ flex: 1 }}>
              <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/login" element={<LoginPage />} />
                <Route path="/profile" element={<PrivateRoute element={<ProfilePage />} />} />
                <Route path="/category/:id" element={<CategoriesPage />} />
                <Route path="/cookie-policy" element={<CookiePolicyPage />} />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                <Route path="/data-processing-policy" element={<DataProcessingPolicyPage />} />
                <Route path="/story/:id" element={<NewsDetailPage />} />
                <Route path="*" element={<NotFound />} />
              </Routes>
            </Box>
            <Footer />
            <ScrollToTop />
          </Box>
        </Router>
      </CategoriesProvider>
    </ThemeProvider>
  );
}

export default App;
