import React from 'react';
import { Container, Typography, Box } from '@mui/material';
import RegistrationForm from '../components/Header/RegistrationForm/RegistrationForm'; 
import { Helmet } from 'react-helmet';

const LoginPage = () => {
  return (
    <Container maxWidth="sm">
        <Helmet>
        <title>Вход в систему | Регистрация</title>
        <meta name="description" content="Войдите в систему или создайте новый аккаунт для доступа к персонализированным услугам." />
        <meta property="og:title" content="Вход в систему | Регистрация" />
        <meta
          property="og:description"
          content="Войдите в систему или создайте новый аккаунт для получения персонализированного контента и услуг."
        />
       
        <meta property="og:url" content="https://example.com/login" />
        <meta property="og:type" content="website" />
      </Helmet>
      <Box sx={{ my: 4, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Box mb={3}>
          <Typography fontWeight={600} variant="h6" align="center">
            Войдите в систему или зарегистрируйтесь
          </Typography>
        </Box>
        {/* Используем компонент RegistrationForm */}
        <RegistrationForm isModal={false} />
      </Box>
    </Container>
  );
};

export default LoginPage;
