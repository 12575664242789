import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Container, Box, Grid, Typography, Skeleton } from "@mui/material";
import { Helmet } from "react-helmet";
import api from "../api";

import CategorySlider from "../components/CategoryPage/CategorySlider";
import NewsHeader from "../components/NewsDetailPage/NewsHeader";
import NewsMainContent from "../components/NewsDetailPage/NewsMainContent";
import RelatedArticles from "../components/NewsDetailPage/RelatedArticles";
import RelevantSideBar from "../components/RelevantSideBar/RelevantSideBar";
import RecommendationsWithAd from "../components/NewsDetailPage/RecommendationsWithAd";
import EditorialPicks from "../components/HomePage/EditorialPicks";
import StoryNotFound from "../components/NewsDetailPage/StoryNotFound";

import { useCategories } from "../context/CategoriesContext";

const NewsDetailPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { categories, loading: categoriesLoading } = useCategories();
  const [story, setStory] = useState(null);
  const [loading, setLoading] = useState(true);
  const [selectedArticle, setSelectedArticle] = useState(null);
  const [imagesForSlider, setImagesForSlider] = useState([]);
  const [reactions, setReactions] = useState([]);
  const [relatedStories, setRelatedStories] = useState([]);
  const [error, setError] = useState(null);

  // Отслеживание активности пользователя
  const trackUserActivity = async (storyId) => {
    try {
      await api.post("/api/v1/user-activity/track_activity/", {
        story_id: storyId,
      });
    } catch (error) {
      console.error("Ошибка при фиксации активности пользователя:", error);
    }
  };

  // Загрузка данных истории, реакций и связанных историй
  useEffect(() => {
    const fetchStoryDetails = async () => {
      setLoading(true);
      try {
        const response = await api.get(`/api/v1/story-detail/${id}/`);
        const storyData = response.data;

        setStory(storyData);
        setSelectedArticle(storyData.news_articles[0]); // Устанавливаем первую статью как выбранную
        setImagesForSlider(storyData.main_images || []); // Если main_images отсутствует, устанавливаем пустой массив

        if (storyData) {
          trackUserActivity(storyData.id);

          // Загружаем реакции
          const reactionsResponse = await api.get(
            `/api/v1/reactions/list/story/${id}/`
          );
          setReactions(reactionsResponse.data);

          // Загружаем связанные истории
          const relatedResponse = await api.get(
            `/api/v1/stories/?category=${storyData.category.id}&page_size=5`
          );
          const filteredStories = relatedResponse.data.results.filter(
            (story) => story.id !== parseInt(id, 10)
          );
          setRelatedStories(filteredStories);
        }
      } catch (error) {
        console.error("Ошибка при загрузке данных истории:", error);
        setError("Сюжет не найден.");
        setStory(null);
      } finally {
        setLoading(false);
      }
    };

    if (id) {
      fetchStoryDetails();
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, [id]);

  const handleArticleClick = (article) => {
    setSelectedArticle(article);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleSelectCategory = (categoryId) => {
    navigate(`/category/${categoryId}`);
  };

  const handleRelevantStoryClick = (storyId) => {
    if (id !== storyId) {
      navigate(`/story/${storyId}`);
    }
  };

  if (!loading && !story) {
    return <StoryNotFound />;
  }

  return (
    <Container>
      {/* SEO метатеги */}
      <Helmet>
        <html lang="ru" />
        <title>{selectedArticle?.title || "Новости Беларуси"}</title>
        <meta
          name="description"
          content={
            story?.description ||
            selectedArticle?.summary ||
            selectedArticle?.title ||
            "Новости Беларуси — актуальные события в Беларуси и мире."
          }
        />
        <meta name="keywords" content={story?.category?.name} />
        {/* Open Graph */}
        <meta property="og:title" content={selectedArticle?.title} />
        <meta
          property="og:description"
          content={selectedArticle?.summary || selectedArticle?.description}
        />
        <meta
          property="og:image"
          content={
            imagesForSlider?.length > 0
              ? imagesForSlider[0]?.url || imagesForSlider[0]
              : story?.main_images?.[0] || ""
          }
        />
        <meta property="og:url" content={`https://zn.by/story/${id}`} />
        {/* JSON-LD микроданные для статьи */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "http://schema.org",
            "@type": "NewsArticle",
            headline: selectedArticle?.title,
            description:
              selectedArticle?.summary || selectedArticle?.description,
            datePublished: selectedArticle?.publication_at,
            dateModified: selectedArticle?.last_modified_at,
            image: imagesForSlider[0]?.url || "",
            publisher: {
              "@type": "Organization",
              name: "Новости Беларуси",
              logo: "../logo.png",
            },
            mainEntityOfPage: `https://zn.by/story/${id}`,
          })}
        </script>
      </Helmet>

      {error && (
        <Typography variant="body1" color="error" align="center" sx={{ mb: 2 }}>
          {error}
        </Typography>
      )}

      {/* Слайдер категорий */}
      <Box sx={{ marginTop: "40px", marginBottom: "20px" }}>
        {categoriesLoading ? (
          <Skeleton variant="rectangular" height={50} width="100%" />
        ) : (
          categories.length > 0 && (
            <CategorySlider
              categories={categories}
              selectedCategory={story?.category?.id}
              onSelectCategory={handleSelectCategory}
            />
          )
        )}
      </Box>

      <Grid container spacing={3}>
        {/* Главная контентная часть */}
        <Grid item xs={12} md={9}>
          {loading ? (
            <>
              <Skeleton variant="text" height={50} width="60%" sx={{ mb: 2 }} />
              <Skeleton variant="rectangular" height={300} sx={{ mb: 2 }} />
              <Skeleton
                variant="text"
                height={20}
                width="100%"
                sx={{ mb: 1 }}
              />
            </>
          ) : (
            <>
              {selectedArticle && (
                <>
                  <NewsHeader
                    key={selectedArticle.id}
                    source={selectedArticle.source}
                    creationDate={selectedArticle.publication_at}
                    title={selectedArticle.title}
                    shareUrl={`https://zn.by/story/${id}`}
                  />

                  <Box mt={1}>
                    <NewsMainContent
                      mainImages={imagesForSlider}
                      text={
                        selectedArticle.summary || selectedArticle.description
                      }
                      url={selectedArticle.url}
                      articleId={id}
                      initialReactions={reactions}
                      description={story?.description}
                      sourceName={selectedArticle.source?.name}
                    />
                  </Box>

                  <Box mt={4} mb={4}>
                    <RelatedArticles
                      articles={story.news_articles}
                      onArticleClick={handleArticleClick}
                      selectedArticle={selectedArticle}
                    />
                  </Box>
                </>
              )}

              <Box marginBottom={2}>
                <RecommendationsWithAd
                  type="recommendations"
                  reactions={reactions}
                />
              </Box>
            </>
          )}
        </Grid>

        {/* Боковая панель с рекомендациями */}
        <Grid item xs={12} md={3}>
          <Box>
            {loading ? (
              <Skeleton variant="rectangular" height={400} />
            ) : (
              <RelevantSideBar
                excludeId={id}
                currentCategoryId={story?.category?.id}
                relatedStories={relatedStories}
                reactions={reactions}
                onStoryClick={handleRelevantStoryClick}
              />
            )}
          </Box>

          {/* Рекомендуемые материалы */}
          <Box
            sx={{
              borderRadius: "12px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              overflow: "hidden",
              mt: { xs: 0, sm: 0, md: 2 },
              mb: 2,
            }}
          >
            {loading ? (
              <Skeleton variant="rectangular" height={200} width="100%" />
            ) : (
              <EditorialPicks reactions={reactions} />
            )}
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default NewsDetailPage;
