import React, { useState } from "react";
import { IconButton, Avatar, CircularProgress } from "@mui/material";
import UserMenu from "./UserMenu";
import { useUser } from "../../context/UserContext";
import AuthButton from "./Auth/AuthButton";

const UserPanel = () => {
  const { user, loading } = useUser();
  const [anchorEl, setAnchorEl] = useState(null);
  const { logout } = useUser();

  const getInitials = () => {
    if (!user) return "";
    const firstNameInitial = user.first_name
      ? user.first_name[0].toUpperCase()
      : "";
    const lastNameInitial = user.last_name
      ? user.last_name[0].toUpperCase()
      : "";
    return `${firstNameInitial}${lastNameInitial}`;
  };

  if (loading) {
    return <CircularProgress />;
  }

  const handleLogout = () => {
    logout(); // Вызываем метод logout при клике на выход
    setAnchorEl(null); // Закрываем меню после выхода
  };

  return user ? (
    <>
      <IconButton
        onClick={(e) => setAnchorEl(e.currentTarget)}
        color="inherit"
        sx={{ fontSize: 24 }}
      >
        {user.avatar ? (
          <Avatar
            src={user.avatar}
            alt={user.username}
            sx={{ width: 35, height: 35 }}
          />
        ) : (
          <Avatar sx={{ width: 35, height: 35 }}>{getInitials()}</Avatar>
        )}
      </IconButton>
      <UserMenu
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        handleLogout={handleLogout} 
      />
    </>
  ) : (
    <AuthButton />
  );
};

export default UserPanel;
