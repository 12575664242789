import React, { useState } from "react";
import { Drawer, List, ListItem, ListItemText, TextField, Box } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import { IconButton } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";

const HamburgerMenu = ({ categories = [] }) => {
  const [open, setOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');  
  const location = useLocation();
  const navigate = useNavigate();

  // Определение активной категории на основе текущего URL
  const activeCategory = categories?.find((category) =>
    location.pathname.includes(`/category/${category.id}`)
  );

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  const handleCategoryClick = (categoryId) => {
    navigate(`/category/${categoryId}`);
    setOpen(false); // Закрытие меню после выбора категории
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleSearchSubmit = (event) => {
    event.preventDefault();
    navigate(`/search?query=${encodeURIComponent(searchQuery)}`);
    setOpen(false); // Закрытие меню после выполнения поиска
  };

  return (
    <>
      {/* Иконка для открытия меню */}
      <IconButton onClick={toggleDrawer(true)} sx={{ color: "text.primary" }}>
        <MenuIcon />
      </IconButton>

      {/* Drawer для меню */}
      <Drawer anchor="right" open={open} onClose={toggleDrawer(false)}>
        <Box sx={{ width: 250, padding: 2 }}>
          {/* Поле поиска */}
          <form onSubmit={handleSearchSubmit}>
            <TextField
              value={searchQuery}
              onChange={handleSearchChange}
              label="Поиск"
              variant="outlined"
              fullWidth
            />
          </form>

          {/* Список категорий */}
          <List>
            {categories.map((category) => (
              <ListItem
                button
                key={category.id}
                onClick={() => handleCategoryClick(category.id)}
                selected={activeCategory?.id === category.id} // Выделяем активную категорию
              >
                <ListItemText primary={category.name} />
              </ListItem>
            ))}
          </List>
        </Box>
      </Drawer>
    </>
  );
};

export default HamburgerMenu;
