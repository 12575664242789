import React, { useState, useEffect } from "react";
import {
  AppBar,
  Toolbar,
  Container,
  Grid,
  Box,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import ThemeSwitcher from "./ThemeSwitcher";
import UserPanel from "./UserPanel";
import Logo from "./Logo";
import SearchBar from "./SearchBar";
import HamburgerMenu from "./HamburgerMenu";
import WeatherAndCurrencies from "./WeatherAndCurrencies";
import api from "../../api"; // Импортируем API для получения категорий

const Header = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [categories, setCategories] = useState([]); // Храним категории в состоянии

  // Загружаем категории при монтировании компонента
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await api.get("/api/v1/categories/"); // Пример API-запроса для получения категорий
        setCategories(response.data); // Сохраняем полученные категории в состояние
      } catch (error) {
        console.error("Ошибка при загрузке категорий:", error);
      }
    };
    fetchCategories();
  }, []); // Запрос выполняется только один раз при монтировании

  return (
    <AppBar position="static" color="default" sx={{ paddingY: 0.5, boxShadow: 0 }}>
      <div id="back-to-top-anchor" />
      <Container maxWidth="lg">
        {/* Основная строка */}
        <Toolbar disableGutters sx={{ pr: 0 }}>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            sx={{ pr: 0, paddingTop: 2 }}
          >
            {/* Логотип */}
            <Grid item>
              <Logo />
            </Grid>

            {/* Поиск (скрывается на мобильных) */}
            {!isMobile && (
              <Grid item xs={6}>
                <Box sx={{ mx: 2 }}>
                  <SearchBar />
                </Box>
              </Grid>
            )}

            {/* Элементы справа */}
            <Grid
              item
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end", // Прижимаем элементы к правому краю
                gap: 1, // Расстояние между элементами
                pr: 0, // Убираем padding справа
              }}
            >
              <ThemeSwitcher />
              <UserPanel />
              {isMobile && <HamburgerMenu categories={categories} />} {/* Передаем категории в HamburgerMenu */}
            </Grid>
          </Grid>
        </Toolbar>

        {/* Нижняя строка (погода и валюты) */}
        <Box sx={{ mt: 1 }}>
          <Grid container alignItems="center" justifyContent="space-between">
            <WeatherAndCurrencies />
          </Grid>
        </Box>
      </Container>
    </AppBar>
  );
};

export default Header;
