import React from 'react';
import { styled, alpha, InputBase } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius * 2, // Более плавные углы
  backgroundColor: theme.palette.mode === 'dark' 
    ? alpha(theme.palette.common.black, 0.8) // Темная тема
    : alpha(theme.palette.common.white, 0.9), // Светлая тема
  border: `1px solid ${theme.palette.mode === 'dark' ? theme.palette.divider : '#ccc'}`,
  boxShadow: theme.palette.mode === 'dark' 
    ? '0 4px 8px rgba(0, 0, 0, 0.4)' // Темная тема
    : '0 4px 8px rgba(0, 0, 0, 0.1)', // Светлая тема
  width: '100%',
  maxWidth: 800,
  margin: '0 auto',
  display: 'flex',
  alignItems: 'center',
  transition: 'all 0.3s ease', // Анимация для hover
  '&:hover': {
    boxShadow: theme.palette.mode === 'dark' 
      ? '0 6px 12px rgba(0, 0, 0, 0.6)' // Темная тема
      : '0 6px 12px rgba(0, 0, 0, 0.2)', // Светлая тема
    transform: 'scale(1.02)', // Легкое увеличение
  },
  [theme.breakpoints.down('md')]: {
    maxWidth: '90%', // Для мобильных устройств
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: theme.palette.text.secondary, // Цвет текста из темы
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: theme.palette.text.primary, // Основной цвет текста
  width: '100%',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1.5, 1.5, 1.5, 0), // Более крупный отступ
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    fontSize: '1rem',
    fontWeight: 400, // Легкий вес текста
    width: '100%',
  },
}));

const SearchBar = () => {
  return (
    <Search>
      <SearchIconWrapper>
        <SearchIcon />
      </SearchIconWrapper>
      <StyledInputBase
        placeholder="Поиск новостей..."
        inputProps={{ 'aria-label': 'search' }}
      />
    </Search>
  );
};

export default SearchBar;
