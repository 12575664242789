import React from "react";
import Helmet from 'react-helmet';
import { Container, Typography, Link, List, ListItem } from "@mui/material";

const CookiePolicyPage = () => {
  return (
    <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
      <Helmet>
        <title>Политика обработки файлов cookie</title>
        <meta
          name="description"
          content="Узнайте, как мы обрабатываем файлы cookie, обеспечиваем их защиту и как вы можете управлять ими."
        />
        <meta property="og:title" content="Политика обработки файлов cookie" />
        <meta
          property="og:description"
          content="Узнайте, как мы обрабатываем файлы cookie, обеспечиваем их защиту и как вы можете управлять ими."
        />
        <meta
          property="og:image"
          content="https://example.com/images/cookie-policy-banner.jpg"
        />
        <meta property="og:url" content="https://example.com/cookie-policy" />
        <meta property="og:type" content="article" />
      </Helmet>

      <Typography variant="h4" gutterBottom>
        Обработка файлов cookie
      </Typography>
      <Typography variant="h6" gutterBottom>
        ПОЛИТИКА В ОТНОШЕНИИ ОБРАБОТКИ ФАЙЛОВ COOKIE
      </Typography>
      <Typography variant="body1" gutterBottom>
        ООО «Организация которая делает сайт»
      </Typography>

      <Typography variant="h6" gutterBottom>
        1. Что такое файлы cookie?
      </Typography>
      <Typography variant="body1" gutterBottom>
        Файлы сookie — это текстовые файлы, сохраненные в браузере компьютера
        (мобильного устройства) пользователя официального интернет-сайта ООО
        «Триовист» (далее — сайт), при его посещении с целью отражения
        совершенных действий. Файлы сookie могут собираться,
        систематизироваться, храниться, изменяться, использоваться,
        обезличиваться, блокироваться, удаляться с использованием средств
        автоматизации.
      </Typography>
      <Typography variant="body1" gutterBottom>
        Целью обработки файлов сookie является обеспечение корректного и
        безопасного использования предлагаемых на сайте возможностей и услуг,
        удобства пользователей сайта, повышение качества его функционирования,
        создания персонализированной рекламы и сбора аналитической информации.
        ООО «Триовист» не использует файлы сookie для идентификации субъектов
        персональных данных.
      </Typography>

      <Typography variant="h6" gutterBottom>
        2. Какие файлы сookie и для чего мы обрабатываем?
      </Typography>
      <Typography variant="body1" gutterBottom>
        На сайте обрабатываются следующие типы файлов сookie:
      </Typography>
      <List>
        <ListItem>
          <Typography variant="body1">
            <strong>Необходимые файлы сookie</strong> — используются для
            функционирования корректной работы сайта, не могут быть отключены в
            наших системах (вместе с тем, пользователь может настроить браузер,
            чтобы он блокировал такие файлы сookie или уведомлял пользователя об
            их использовании);
          </Typography>
        </ListItem>
        <ListItem>
          <Typography variant="body1">
            <strong>Функциональные файлы сookie</strong> — являются критически
            важными для работы отдельных страниц сайта и обеспечивают работу
            полезных функций, например, для формирования подборки новостей в
            разделе рекомендаций;
          </Typography>
        </ListItem>
        <ListItem>
          <Typography variant="body1">
            <strong>Аналитические файлы сookie</strong> — могут использоваться
            для сбора данных об интересах пользователей, посещаемых страницах и
            источниках трафика, чтобы оценивать и улучшать работу нашего сайта.
          </Typography>
        </ListItem>
      </List>

      <Typography variant="h6" gutterBottom>
        3. Как долго мы обрабатываем файлы сookie?
      </Typography>
      <Typography variant="body1" gutterBottom>
        Срок, на который дается согласие пользователем сайта в отношении
        обработки файлов сookie, составляет один год. Вместе с тем, с целью
        соблюдения безызбыточности обработки файлов сookie, основная их часть
        обрабатывается не более одного дня, после чего файлы сookie удаляются с
        помощью использования средств автоматизации.
      </Typography>

      <Typography variant="h6" gutterBottom>
        4. Кто нам помогает обрабатывать файлы сookie?
      </Typography>
      <Typography variant="body1" gutterBottom>
        Мы поручаем обработку функциональных и аналитических файлов сookie нашим
        отделам по маркетингу и аналитике.
      </Typography>

      <Typography variant="h6" gutterBottom>
        4. Как можно настроить обработку файлов сookie?
      </Typography>
      <Typography variant="body1" gutterBottom>
        Пользователи могут принять или отклонить обработку функциональных и
        аналитических файлов cookie на сайте, обработку необходимых файлов
        cookie пользователь может настроить у себя в браузере, при этом
        корректная работа сайта возможна только в случае использования
        необходимых файлов cookie.
      </Typography>
      <Typography variant="body1" gutterBottom>
        В настройках своего браузера пользователи могут регулировать сбор всех
        или некоторых файлов сookie, некоторые браузеры позволяют посещать
        интернет-сайты в режиме «инкогнито», чтобы ограничить хранимый на
        компьютере объем информации и автоматически удалять некоторые типы
        файлов cookie.
      </Typography>
      <Typography variant="body1" gutterBottom>
        Кроме того, пользователь может удалить ранее сохраненные файлы сookie,
        выбрав соответствующую опцию в истории браузера.
      </Typography>
      <Typography variant="body1" gutterBottom>
        Подробнее о параметрах управления сookie можно ознакомиться, перейдя по
        внешним ссылкам, ведущим на соответствующие страницы сайтов основных
        браузеров:
      </Typography>

      <List>
        <ListItem>
          <Link href="https://support.mozilla.org/ru/kb/udalenie-kukov-dlya-udaleniya-informacii-kotoruyu-">
            Firefox
          </Link>
        </ListItem>
        <ListItem>
          <Link href="https://support.google.com/chrome/answer/95647?hl=ru">
            Chrome
          </Link>
        </ListItem>
        <ListItem>
          <Link href="https://support.apple.com/ru-ru/guide/safari/sfri11471/mac">
            Safari
          </Link>
        </ListItem>
        <ListItem>
          <Link href="https://help.opera.com/ru/latest/web-preferences/#Управление-файлами-cookie">
            Opera
          </Link>
        </ListItem>
        <ListItem>
          <Link href="https://support.microsoft.com/ru-ru/microsoft-edge/удаление-файлов-cookie-в-microsoft-edge-63947406-40ac-c3b8-57b9-2a946a29ae09">
            Microsoft Edge
          </Link>
        </ListItem>
        <ListItem>
          <Link href="https://support.microsoft.com/ru-ru/windows/удаление-файлов-cookie-и-управление-ими-168dab11-0753-043d-7c16-ede5947fc64d">
            Internet Explorer
          </Link>
        </ListItem>
      </List>
    </Container>
  );
};

export default CookiePolicyPage;
