import { React } from "react";
import { Box, Grid, Container } from "@mui/material";
import NewsSection from "../components/NewsSection";
import CategoryNewsList from "../components/HomePage/CategoryNewsList";
import EditorialPicks from "../components/HomePage/EditorialPicks";
import {Helmet} from 'react-helmet'; 
import AdBanner from "../components/HomePage/AdBanner";

const HomePage = () => {  
  const imageSrc = "/static/bg.png";

  return (    

    <Container
      maxWidth="lg"
      sx={{
        mt: { xs: 1, md: 2 }, // Уменьшение отступа сверху в мобильной версии
        paddingLeft: { xs: 0, sm: 2 },
        paddingRight: { xs: 0, sm: 2 },
      }}
    >
       <Helmet>
        <title>Новости Беларуси</title>
        <meta name="description" content="Актуальные новости, аналитика и мнения о событиях в Беларуси и мире." />
        <meta name="keywords" content="Новости, Беларусь, события, политика, экономика" />
        <meta property="og:title" content="Новости Беларуси" />
        <meta property="og:description" content="Актуальные новости, аналитика и мнения о событиях в Беларуси и мире." />
        <meta property="og:image" content="/images/og-image.jpg" />
      </Helmet>
      <Grid container spacing={2}>
        <Grid item xs={12} md={9}>
          <CategoryNewsList />
        </Grid>

        <Grid
          item
          xs={12}
          md={3}
          sx={{
            mt: { xs: 0, md: "50px" }, // Убираем верхний отступ на мобильных устройствах
            display: { xs: "none", md: "block" }, // Полностью скрываем блок на мобильных
          }}
        >
          <AdBanner imageSrc={imageSrc} />
        </Grid>

        <Grid item xs={12} md={9}>
          <Box sx={{ mb: { xs: 0.5, md: 1 } }}>
            {" "}
            {/* Уменьшение нижнего отступа в мобильной версии */}
            <NewsSection type="popular" />
          </Box>

          <Box sx={{ mb: { xs: 0.5, md: 2 }, mt: { xs: 3, md: 5 } }}>
            {" "}
            {/* Уменьшение верхнего и нижнего отступов */}
            <NewsSection type="recommendations" />
          </Box>
        </Grid>

        <Grid item xs={12} md={3} mt={2}>
          <Box
            sx={{
              borderRadius: "12px",
              paddingTop: "0px",
              paddingBottom: "15px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              overflow: "hidden",
              mt: { xs: 1, md: 2 }, 
              mb: { xs: 1, sm: 0 },
            }}
          >
            <EditorialPicks />
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default HomePage;
