import React from "react";
import { Card, Box, Typography } from "@mui/material";
import NewsImage from "./NewsCard/NewsImage";
import NewsContent from "./NewsCard/NewsContent";
import NewsFooter from "./NewsCard/NewsFooter";
import { timeAgo } from "../utils/timeAgoUtils";

const NewsCard = ({ item, time, theme }) => {
  const hasImage = Boolean(item?.main_image);

  if (hasImage) {
    return (
      <Card
        sx={{
          height: hasImage ? "360px" : "240px", // Высота зависит от наличия изображения
          display: "flex",
          flexDirection: "column",
          borderRadius: "10px",
          bgcolor: `${
            theme.palette.mode === "dark" ? "#58585854" : "#ebebebff"
          }`,
          border: `1px solid ${
            theme.palette.mode === "dark" ? "#b0b0b054" : "transparent"
          }`,
          position: "relative",
          transition: "box-shadow 0.3s ease-in-out",
          "&:hover": {
            boxShadow:
              theme.palette.mode === "dark"
                ? "0px 4px 12px rgba(180, 180, 180, 0.774)"
                : "0px 4px 12px rgba(0, 0, 0, 0.39)",
          },
          width: "100%",
        }}
      >
        {hasImage && (
          <NewsImage
            image={item.main_image}
            category={item.category || { name: "Без категории" }} // Передаем объект с категорией или заглушку
          />
        )}
        <Box
          sx={{
            padding: "10px",
            flex: 1,
            overflow: "hidden",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <NewsContent
            title={item?.title || "Без названия"}
            summary={item?.summary}
            description={item?.description}
            text={item?.text}
          />
          <NewsFooter publicationDate={timeAgo(time)} />
        </Box>
      </Card>
    );
  }

  return (
    <Card
      sx={{
        height: "360px", // Фиксированная высота
        display: "flex",
        flexDirection: "column",
        borderRadius: "10px",
        bgcolor: `${theme.palette.mode === "dark" ? "#58585854" : "#f9f9f9"}`,
        border: `1px solid ${
          theme.palette.mode === "dark" ? "#b0b0b054" : "#d9d9d9"
        }`,
        position: "relative",
        transition: "box-shadow 0.3s ease-in-out",
        "&:hover": {
          boxShadow:
            theme.palette.mode === "dark"
              ? "0px 4px 12px rgba(180, 180, 180, 0.774)"
              : "0px 4px 12px rgba(0, 0, 0, 0.2)",
        },
        width: "100%",
      }}
    >
      {/* Категория */}
      {item?.category?.name && (
        <Box
          sx={{
            position: "absolute",
            top: 10,
            right: 10,
            backgroundColor: "rgba(0, 0, 0, 0.7)",
            color: "white",
            padding: "4px 8px",
            fontSize: "0.75rem",
            fontWeight: "bold",
            borderRadius: "2px",
          }}
        >
          {item.category.name}
        </Box>
      )}

      {/* Контент карточки */}
      <Box
        sx={{
          marginTop: "30px",
          padding: "16px",
          flex: 1,
          overflow: "hidden",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        {/* Заголовок */}
        <Typography
          variant="h6"
          sx={{
            fontSize: "16px",
            fontWeight: 700,
            lineHeight: "1.3",
            mb: 1,
            color: theme.palette.mode === "dark" ? "#ffffff" : "#000000",
          }}
        >
          {item?.title || "Без названия"}
        </Typography>
        
        {/* Описание */}
      
          <Typography
            variant="body2"
            sx={{
              fontSize: "14px",
              color: theme.palette.mode === "dark" ? "#d3d3d3" : "#6f6f6f",
              lineHeight: "1.5",
              overflow: "hidden",
              textOverflow: "ellipsis",
              display: "-webkit-box",
              WebkitLineClamp: 3, // Ограничиваем текст тремя строками
              WebkitBoxOrient: "vertical",
              mb: 1,
            }}
          >
            {item?.summary}{" "}
            {/* Показываем summary, если есть; иначе description */}
          </Typography>
        

        {/* Время публикации */}
        <NewsFooter publicationDate={timeAgo(time)} />
      </Box>
    </Card>
  );
};

export default NewsCard;
