import { React, useCallback, useEffect, useState } from "react";
import { Container, Grid, Box } from "@mui/material";
import { useParams, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import CategorySlider from "../components/CategoryPage/CategorySlider";
import NewsSection from "../components/NewsSection";
import EditorialPicks from "../components/HomePage/EditorialPicks";
import AdBanner from "../components/HomePage/AdBanner";
import api from "../api";

const CategoriesPage = () => {
  const { id } = useParams(); // Получаем ID категории из URL
  const navigate = useNavigate();

  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [editorialStoryIds, setEditorialStoryIds] = useState([]);

  useEffect(() => {
    // Загружаем категории
    api
      .get("/api/v1/categories/")
      .then((response) => {
        setCategories(response.data);
        // Если ID из URL нет, выбираем первую категорию
        if (!id && response.data.length > 0) {
          const defaultCategory = response.data[0];
          setSelectedCategory(defaultCategory);
          navigate(`/category/${defaultCategory.id}`);
        } else if (id) {
          // Если ID есть, находим соответствующую категорию
          const foundCategory = response.data.find(
            (cat) => cat.id === parseInt(id, 10)
          );
          setSelectedCategory(foundCategory || null);
        }
      })
      .catch((error) => console.error("Ошибка при загрузке категорий:", error));

    // Загружаем истории редакции
    api
      .get("/api/v1/stories/?ordering=-is_pinned_order,-score&pinned=true")
      .then((response) => {
        const storyIds = response.data.results.map((story) => story.id);
        setEditorialStoryIds(storyIds);
      })
      .catch((error) =>
        console.error("Ошибка при загрузке историй редакции:", error)
      );
  }, [id, navigate]);

  const handleSelectCategory = useCallback(
    (categoryId) => {
      const selected = categories.find(
        (category) => category.id === categoryId
      );
      if (selected) {
        setSelectedCategory(selected); // Устанавливаем выбранную категорию
        navigate(`/category/${selected.id}`); // Меняем URL
      }
    },
    [categories, navigate]
  );

  return (
    <Container
      maxWidth="lg"
      sx={{
        mt: 2,
        mb: 4,
        paddingLeft: { xs: 0, sm: 2 },
        paddingRight: { xs: 0, sm: 2 },
      }}
    >
      <Helmet>
        <title>
          {selectedCategory
            ? `Категория: ${selectedCategory.name}`
            : "Категории"}
        </title>
        <meta
          name="description"
          content={
            selectedCategory
              ? `Ознакомьтесь с последними новостями в категории "${selectedCategory.name}".`
              : "Просмотрите все доступные категории и выберите интересующую вас."
          }
        />
        {/* Open Graph Meta Tags */}
        <meta
          property="og:title"
          content={
            selectedCategory
              ? `Категория: ${selectedCategory.name}`
              : "Категории"
          }
        />
        <meta
          property="og:description"
          content={
            selectedCategory
              ? `Ознакомьтесь с последними новостями в категории "${selectedCategory.name}".`
              : "Просмотрите все доступные категории и выберите интересующую вас."
          }
        />        
        <meta
          property="og:url"
          content={
            selectedCategory
              ? `https://example.com/category/${selectedCategory.id}`
              : "https://example.com/categories"
          }
        />
        <meta property="og:type" content="website" />
      </Helmet>

      <Grid container spacing={2}>
        <Grid item xs={12} md={9}>
          <Box sx={{ width: "100%", display: "flex", flexDirection: "column" }}>
            <CategorySlider
              categories={categories}
              onSelectCategory={handleSelectCategory}
              selectedCategory={selectedCategory?.id}
            />
            <Box mt={4}>
              <NewsSection selectedCategory={selectedCategory} />
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12} md={3} sx={{ mt: "25px" }}>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginBottom: 2,
            }}
          >
            <AdBanner imageSrc="/static/bg.png" />
          </Box>

          <Box
            sx={{
              borderRadius: "12px",
              paddingTop: "12px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              overflow: "hidden",
              mt: 2,
              mb: { xs: 2, sm: 0 },
            }}
          >
            <EditorialPicks storyIds={editorialStoryIds} />
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default CategoriesPage;
