import React, { useRef, useEffect } from 'react';
import { Box, Avatar, Typography, Button } from '@mui/material';

const UserInfo = ({ user, handleEditProfile }) => {
  const nameRef = useRef(null);

  useEffect(() => {
    if (nameRef.current && nameRef.current.scrollWidth > nameRef.current.clientWidth) {
      // Можете добавить логику для обработки длинных имен, если нужно
    }
  }, [user.first_name, user.last_name]);

  return (
    <Box
      sx={{
        borderRadius: '8px',
        p: 2,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      {user && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            textAlign: 'center',
            width: '100%',
            '@media (max-width: 900px)': {
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
              textAlign: 'left',
            },
          }}
        >
          <Avatar
            src={user.avatar || 'https://via.placeholder.com/150'}
            alt={user.username}
            sx={{
              width: 100,
              height: 100,
              '@media (max-width: 900px)': {
                width: 60,
                height: 60,
              },
            }}
          />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              textAlign: 'center',
              '@media (max-width: 900px)': {
                alignItems: 'flex-start',
                textAlign: 'left',
                marginLeft: '10px',
                flex: 1,
              },
            }}
          >
            <Typography
              variant="h6"
              ref={nameRef}
              title={`${user.first_name} ${user.last_name}`}
              sx={{
                fontSize: '1rem',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                maxWidth: '200px',
                '@media (max-width: 900px)': {
                  fontSize: '0.875rem',
                  maxWidth: '150px',
                },
              }}
            >
              {`${user.first_name} ${user.last_name}`}
            </Typography>
            <Typography
              variant="body2"
              color="textSecondary"
              title={user.email}
              sx={{
                fontSize: '0.875rem',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                maxWidth: '200px',
                '@media (max-width: 900px)': {
                  fontSize: '0.75rem',
                  maxWidth: '150px',
                },
              }}
            >
              {user.email}
            </Typography>
            <Button
              variant="contained"
              sx={{
                mt: 2,
                px: 3,
                py: 1,
                fontSize: '1rem',
                '@media (max-width: 900px)': {
                  mt: 1,
                  px: 1,
                  py: 0,
                  fontSize: '0.875rem',
                  alignSelf: 'flex-start',
                },
              }}
              onClick={handleEditProfile}
            >
              Редактировать
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default UserInfo;
