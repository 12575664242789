import React, { useState, useEffect } from 'react';
import { Box, Skeleton } from '@mui/material';
import api from '../../api';
import NewsSection from '../NewsSection';

const UserRecommendations = () => {
  const [recommendations, setRecommendations] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [hasRecommendations, setHasRecommendations] = useState(false);

  useEffect(() => {
    const fetchRecommendations = async () => {
      try {
        // Запрос на получение рекомендаций пользователя
        const response = await api.get('/api/v1/news/user-recommendations/');
        if (response.data.length > 0) {
          setRecommendations(response.data);
          setHasRecommendations(true);
        } else {
          // Если нет рекомендаций, используем популярные сюжеты
          const popularResponse = await api.get('/api/v1/stories/popular');
          setRecommendations(popularResponse.data);
          setHasRecommendations(false);
        }
      } catch (error) {
        console.error('Ошибка при получении рекомендаций:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchRecommendations();
  }, []);

  if (isLoading) {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        {Array.from({ length: 5 }).map((_, index) => (
          <Skeleton key={index} variant="rectangular" width="100%" height={80} />
        ))}
      </Box>
    );
  }

  return (
    <NewsSection 
      type={hasRecommendations ? 'recommendations' : 'popular'} 
      stories={recommendations} 
    />
  );
};

export default UserRecommendations;
