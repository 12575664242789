import React, {useEffect} from 'react';
import { Box, Container, Typography } from '@mui/material';
import { Helmet } from 'react-helmet';


const PrivacyPolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Container maxWidth="md">
       <Helmet>
        <title>Политика конфиденциальности</title>
        <meta name="description" content="Ознакомьтесь с нашей политикой конфиденциальности и принципами обработки персональных данных." />
        <meta property="og:title" content="Политика конфиденциальности" />
        <meta property="og:description" content="Ознакомьтесь с нашей политикой конфиденциальности и принципами обработки персональных данных." />
        <meta property="og:image" content="https://example.com/images/privacy-policy-banner.jpg" />
        <meta property="og:url" content="https://example.com/privacy-policy" />
        <meta property="og:type" content="website" />
      </Helmet>

      <Box sx={{ mt: 4, mb: 4 }}>
        <Typography variant="h4" align="center" gutterBottom>
          Политика конфиденциальности
        </Typography>

        <Typography variant="body1" paragraph>
          Настоящая Политика конфиденциальности (далее — Политика) разработана в соответствии с законодательством Республики Беларусь и определяет порядок обработки и защиты персональных данных пользователей сайта ООО "Триовист" (далее — Оператор).
        </Typography>

        <Typography variant="h6" gutterBottom>
          1. Общие положения
        </Typography>
        <Typography variant="body1" paragraph>
          1.1. Оператор признает важность конфиденциальности персональных данных и обязуется обеспечить их защиту при сборе, обработке и хранении в соответствии с требованиями действующего законодательства.
        </Typography>
        <Typography variant="body1" paragraph>
          1.2. Настоящая Политика распространяется на все персональные данные, которые Оператор может получить от пользователя в процессе использования им сайта.
        </Typography>

        <Typography variant="h6" gutterBottom>
          2. Цели обработки персональных данных
        </Typography>
        <Typography variant="body1" paragraph>
          2.1. Персональные данные пользователей могут использоваться Оператором в следующих целях:
        </Typography>
        <ul>
          <li>2.1.1. Идентификация пользователя для предоставления доступа к услугам и функционалу сайта.</li>
          <li>2.1.2. Оказание персонализированных услуг и улучшение качества обслуживания.</li>
          <li>2.1.3. Обработка запросов и заявок от пользователей.</li>
          <li>2.1.4. Проведение маркетинговых акций и рассылок.</li>
          <li>2.1.5. Анализ и исследование пользовательской активности на сайте для улучшения его работы.</li>
        </ul>

        <Typography variant="h6" gutterBottom>
          3. Обработка персональных данных
        </Typography>
        <Typography variant="body1" paragraph>
          3.1. Обработка персональных данных осуществляется на основе принципов законности, справедливости и конфиденциальности.
        </Typography>
        <Typography variant="body1" paragraph>
          3.2. Оператор осуществляет сбор персональных данных с согласия пользователя и в целях, указанных в разделе 2 настоящей Политики.
        </Typography>

        <Typography variant="h6" gutterBottom>
          4. Права пользователя
        </Typography>
        <Typography variant="body1" paragraph>
          4.1. Пользователь имеет право на получение информации о своих персональных данных, их обработке и защите.
        </Typography>
        <Typography variant="body1" paragraph>
          4.2. Пользователь имеет право потребовать от Оператора исправления, блокировки или уничтожения своих персональных данных в случае их неполноты, устаревания или неточности.
        </Typography>

        <Typography variant="h6" gutterBottom>
          5. Защита персональных данных
        </Typography>
        <Typography variant="body1" paragraph>
          5.1. Оператор принимает необходимые организационные и технические меры для защиты персональных данных пользователя от неправомерного доступа, утраты, уничтожения или изменения.
        </Typography>
        <Typography variant="body1" paragraph>
          5.2. Оператор обеспечивает конфиденциальность персональных данных и предотвращает их разглашение третьим лицам без согласия пользователя.
        </Typography>

        <Typography variant="h6" gutterBottom>
          6. Изменения в Политике конфиденциальности
        </Typography>
        <Typography variant="body1" paragraph>
          6.1. Оператор оставляет за собой право вносить изменения в настоящую Политику конфиденциальности в любое время. Новая редакция Политики вступает в силу с момента ее публикации на сайте.
        </Typography>

        <Typography variant="h6" gutterBottom>
          7. Контактная информация
        </Typography>
        <Typography variant="body1" paragraph>
          7.1. По всем вопросам, связанным с обработкой и защитой персональных данных, пользователь может обратиться к Оператору по следующему адресу: info@example.com.
        </Typography>
      </Box>
    </Container>
  );
};

export default PrivacyPolicy;
