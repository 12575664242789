import React, { useState } from "react";
import {
  Box,
  Dialog,
  DialogContent,
  IconButton,
  useMediaQuery,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

const ImageSliderModal = ({ mainImages }) => {
  const [open, setOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleClickOpen = (image, index) => {
    setSelectedImage(image);
    setCurrentIndex(index);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handlePrevious = () => {
    const newIndex = (currentIndex - 1 + mainImages.length) % mainImages.length;
    setSelectedImage(mainImages[newIndex]);
    setCurrentIndex(newIndex);
  };

  const handleNext = () => {
    const newIndex = (currentIndex + 1) % mainImages.length;
    setSelectedImage(mainImages[newIndex]);
    setCurrentIndex(newIndex);
  };

  const handleSwipe = (direction) => {
    if (direction === "left") {
      handleNext();
    } else if (direction === "right") {
      handlePrevious();
    }
  };

  // Определение количества видимых фото в зависимости от ширины экрана
  const isSmallScreen = useMediaQuery("(max-width:549px)");
  const isMediumScreen = useMediaQuery(
    "(min-width:550px) and (max-width:1149px)"
  );
  const isLargeScreen = useMediaQuery("(min-width:1150px)");

  const visibleImagesCount = isSmallScreen
    ? 1
    : isMediumScreen
    ? 2
    : isLargeScreen
    ? 3
    : 0;

  return (
    <>
      <Box sx={{ display: "flex", gap: "10px" }}>
        {mainImages.slice(0, visibleImagesCount).map((image, index) => {
          const isLastVisibleImage = index === visibleImagesCount - 1; // Последняя видимая картинка
          const remainingImages = mainImages.length - visibleImagesCount;

          return (
            <Box
              key={index}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "10px",
                cursor: "pointer",
                width: "100%",
                height: "180px",
                backgroundColor: "transparent",
                overflow: "hidden",
                position: "relative",
              }}
              onClick={() => handleClickOpen(image, index)}
            >
              <img
                src={image}
                alt={`Thumbnail ${index}`}
                style={{
                  maxWidth: "100%",
                  maxHeight: "100%",
                  height: "100%",
                  width: "auto",
                  objectFit: "cover",
                }}
              />
              {isLastVisibleImage && remainingImages > 0 && (
                <Box
                  sx={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    color: "white",
                    fontSize: "16px",
                    fontWeight: "bold",
                  }}
                >
                  +Ещё фото
                </Box>
              )}
            </Box>
          );
        })}
      </Box>

      {/* Модальное окно для увеличенного изображения */}
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="lg"
        fullWidth
        PaperProps={{
          sx: {
            backgroundColor: "transparent",
            boxShadow: "none",
          },
        }}
        onTouchStart={(e) => {
          e.preventDefault();
          const touchStartX = e.changedTouches[0].clientX;
          e.currentTarget.ontouchend = (endEvent) => {
            const touchEndX = endEvent.changedTouches[0].clientX;
            if (touchEndX - touchStartX > 50) handleSwipe("right"); // свайп вправо
            if (touchStartX - touchEndX > 50) handleSwipe("left"); // свайп влево
          };
        }}
      >
        <DialogContent
          sx={{
            position: "relative",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "transparent",
            padding: "0",
          }}
        >
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "fixed",
              right: 16,
              top: 16,
              color: "#fff",
              zIndex: 2,
            }}
          >
            <CloseIcon />
          </IconButton>

          {/* Стрелки для перехода между изображениями */}
          <IconButton
            onClick={handlePrevious}
            sx={{
              position: "absolute",
              left: "20px",
              color: "white",
              zIndex: 2,
            }}
          >
            <ArrowBackIosIcon />
          </IconButton>

          <img
            src={selectedImage}
            loading="lazy"
            alt="Selected Story"
            style={{
              maxWidth: "100%",
              maxHeight: "80vh",
              height: "auto",
              width: "auto",
              borderRadius: "8px",
            }}
          />

          <IconButton
            onClick={handleNext}
            sx={{
              position: "absolute",
              right: "20px",
              color: "white",
              zIndex: 2,
            }}
          >
            <ArrowForwardIosIcon />
          </IconButton>

          {/* Миниатюры внизу экрана с горизонтальной прокруткой */}
          <Box
            sx={{
              position: "fixed",
              bottom: "10px",
              left: "50%",
              transform: "translateX(-50%)",
              display: "flex",
              gap: "5px",
              overflowX: "auto",
              zIndex: 3,
              padding: "5px 10px",
            }}
          >
            {mainImages.map((img, idx) => (
              <Box
                key={idx}
                onClick={() => {
                  setSelectedImage(img);
                  setCurrentIndex(idx);
                }}
                sx={{
                  width: "50px",
                  height: "50px",
                  borderRadius: "4px",
                  backgroundImage: `url(${img})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  border:
                    idx === currentIndex
                      ? "2px solid white"
                      : "2px solid transparent",
                  cursor: "pointer",
                  flexShrink: 0,
                }}
              />
            ))}
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ImageSliderModal;