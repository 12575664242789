import React, { useEffect, useState } from "react";
import { Box, Grid, Typography, useTheme } from "@mui/material";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import EuroIcon from "@mui/icons-material/Euro";
import CurrencyRubleIcon from "@mui/icons-material/CurrencyRuble";
import api from "../../api";

const WeatherAndCurrencies = () => {
  const theme = useTheme(); // Используем хук для получения темы
  const [weather, setWeather] = useState({
    temperature: 0,
    description: "",
    city: "",
  });
  const [currencyRates, setCurrencyRates] = useState([]);
  const currencyIcons = {
    USD: <AttachMoneyIcon />,
    EUR: <EuroIcon />,
    RUB: <CurrencyRubleIcon />,
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const weatherResponse = await api.get("/api/v1/weather/current/");
        setWeather(weatherResponse.data);

        const currencyResponse = await api.get("/api/v1/currency-rates/");
        setCurrencyRates(currencyResponse.data);
      } catch (error) {
        console.error("Ошибка загрузки данных:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <Grid
      container
      spacing={1}
      sx={{
        mb: 1,
        pt: 1,
        alignItems: "center",
        justifyContent: "space-between", // Равномерное распределение
      }}
    >
      {/* Погода */}
      <Grid item>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 0.5,
          }}
        >
          <Box
            component="img"
            sx={{
              width: 12, // Уменьшенный размер иконки
              height: 12,
              filter: theme.palette.mode === "dark" ? "invert(1)" : "none",
            }}
            src={weather.icon}
            alt={weather.description}
          />
          <Typography
            variant="body2" // Используем меньший размер текста
            sx={{
              fontSize: "0.85rem",
              fontWeight: "500",
            }}
          >
            {weather.temperature}° {weather.city}
          </Typography>
        </Box>
      </Grid>

      {/* Валюты */}
      <Grid item>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1, // Меньшее расстояние между валютами
          }}
        >
          {currencyRates.length === 0 ? (
            <Typography
              variant="body2"
              sx={{
                color: "text.secondary",
                fontSize: "0.85rem",
              }}
            >
              Загрузка...
            </Typography>
          ) : (
            currencyRates.map((rate) => (
              <Box
                key={rate.currency}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 0.25, // Минимальное расстояние между иконкой и текстом
                  fontSize: "0.85rem",
                }}
              >
                {React.cloneElement(currencyIcons[rate.currency], {
                  style: { fontSize: "1rem" }, // Изменяем размер иконки
                })}
                <Typography sx={{ fontSize: "0.85rem", fontWeight: 400 }}>
                  {rate.value}
                </Typography>
              </Box>
            ))
          )}
        </Box>
      </Grid>
    </Grid>
  );
};

export default WeatherAndCurrencies;
