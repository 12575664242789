import React, { useState, useEffect } from "react";
import { Box, Grid, Skeleton, Container } from "@mui/material";
import UserRecommendations from "./UserRecommendations";
import ProfileEditForm from "./ProfileEditForm";
import EditorialPicks from "../../components/HomePage/EditorialPicks";
import CategoryNewsList from "../HomePage/CategoryNewsList";
import UserInfo from "./UserInfo";
import { useUser } from "../../context/UserContext";
import { getUser } from "../../api";
import { Helmet } from "react-helmet";

const ProfilePage = () => {
  const { user, setUser } = useUser();
  const [loading, setLoading] = useState(true);
  const [isProfileMenuOpen, setProfileMenuOpen] = useState(false);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const userData = await getUser();
        setUser(userData);
      } catch (error) {
        console.error("Ошибка получения данных пользователя:", error);
      } finally {
        setLoading(false);
      }
    };

    if (loading) {
      fetchUser();
    }
  }, [loading, setUser]);

  const handleEditProfile = () => {
    setProfileMenuOpen(true);
  };

  const handleSaveProfile = (updatedUser) => {
    setUser((prevUser) => ({
      ...prevUser,
      ...updatedUser,
      avatar:
        updatedUser.avatar instanceof File
          ? URL.createObjectURL(updatedUser.avatar)
          : updatedUser.avatar,
    }));
    setProfileMenuOpen(false); // Закрываем форму
  };

  const handleCloseEditModal = () => {
    setProfileMenuOpen(false);
  };

  return (
    <Box>
      <Container maxWidth="lg" disableGutters>
      <Helmet>
        <title>Ваш профиль</title>
        <meta name="description" content="Просмотрите и редактируйте информацию о вашем профиле." />
        <meta property="og:title" content="Ваш профиль" />
        <meta
          property="og:description"
          content="На этой странице вы можете просмотреть и редактировать информацию о вашем профиле, включая личные данные."
        />
        <meta
          property="og:image"
          content="https://example.com/images/profile-banner.jpg" // Замените на реальный путь к изображению
        />
        <meta property="og:url" content="https://example.com/profile" />
        <meta property="og:type" content="website" />
      </Helmet>

        <Grid container spacing={{ xs: 2, md: 1 }}>
          {/* Основной контент: CategoryNewsList и UserRecommendations */}
          <Grid
            item
            xs={12}
            md={9}
            sx={{ order: { xs: 2, md: 1 }, px: { xs: 1, md: 1 } }}
          >
            <Box sx={{ mb: 2 }}>
              {loading ? (
                <Box>
                  <Skeleton
                    variant="rectangular"
                    height={180}
                    width="100%"
                    sx={{ mb: 1, borderRadius: 1 }}
                  />
                  <Skeleton variant="text" width="40%" height={30} />
                  <Skeleton variant="text" width="90%" height={20} />
                  <Skeleton variant="text" width="90%" height={20} />
                  <Skeleton variant="text" width="90%" height={20} />
                </Box>
              ) : (
                <CategoryNewsList />
              )}
            </Box>

            <Box sx={{ mb: 2 }}>
              {loading ? (
                <Box>
                  <Skeleton
                    variant="rectangular"
                    height={180}
                    width="100%"
                    sx={{ mb: 1, borderRadius: 1 }}
                  />
                  <Skeleton variant="text" width="40%" height={30} />
                  <Skeleton variant="text" width="90%" height={20} />
                  <Skeleton variant="text" width="90%" height={20} />
                  <Skeleton variant="text" width="90%" height={20} />
                </Box>
              ) : (
                <UserRecommendations />
              )}
            </Box>
          </Grid>

          {/* Боковая панель: UserInfo и EditorialPicks */}
          <Grid
            item
            xs={12}
            md={3}
            sx={{ order: { xs: 1, md: 2 }, px: { xs: 1, md: 1 } }}
          >
            <Box sx={{ mb: 2, mt: 5 }}>
              {loading ? (
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  gap={1}
                >
                  <Skeleton variant="circular" width={80} height={80} />
                  <Skeleton variant="text" width="60%" height={20} />
                  <Skeleton variant="text" width="80%" height={15} />
                </Box>
              ) : (
                <UserInfo user={user} handleEditProfile={handleEditProfile} />
              )}
            </Box>

            <Box
              sx={{
                borderRadius: "8px",
                mt: { xs: 0, md: 3 },
                pt: 3,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "space-between",
                height: "100%",
                mb: 3,
              }}
            >
              {loading ? (
                <Box>
                  <Skeleton
                    variant="rectangular"
                    width="100%"
                    height={180}
                    sx={{ borderRadius: 1, mb: 1 }}
                  />
                  <Skeleton
                    variant="rectangular"
                    width="100%"
                    height={180}
                    sx={{ borderRadius: 1 }}
                  />
                </Box>
              ) : (
                <EditorialPicks />
              )}
            </Box>
          </Grid>
        </Grid>
      </Container>

      {isProfileMenuOpen && (
        <ProfileEditForm
          user={user}
          onClose={handleCloseEditModal}
          onSave={handleSaveProfile}
        />
      )}
    </Box>
  );
};

export default ProfilePage;
